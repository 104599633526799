<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ connection.name }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="columns margin-xy-2 is-paddingless">
        <div class="column">
          <div class="box">
            <div class="label has-text-black-bis" style="font-size: 18px;">
              Technical Documents
            </div>
            <div class="pin-box-toggle field is-grouped">
              <b-checkbox v-model="licenseeConnectionTemp.techDocsPin">
                Pin
              </b-checkbox>
              <span class="margin-x-2" />
              <b-checkbox v-model="licenseeConnectionTemp.techDocsBox">
                Box
              </b-checkbox>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box">
            <div class="label has-text-black-bis" style="font-size: 18px;">
              Inspection Sheets
            </div>
            <div class="pin-box-toggle field is-grouped">
              <b-checkbox v-model="licenseeConnectionTemp.inspSheetsPin">
                Pin
              </b-checkbox>
              <span class="margin-x-2" />
              <b-checkbox v-model="licenseeConnectionTemp.inspSheetsBox">
                Box
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="b-table scrollable license_editor">
        <div class="table-wrapper">
          <table class="table">
            <thead>
              <tr>
                <th width="23%" class="has-text-centered" style="font-size: 18px; line-height: 1.2">
                  Royalty<br>Requests
                </th>
                <th :class="{ inactive: !licenseeConnectionTemp.manufacture }">
                  <div class="field is-marginless has-text-centered">
                    <b-switch v-model="licenseeConnectionTemp.manufacture" size="is-small">
                      Manufacture
                    </b-switch>
                    <div v-if="licenseeConnectionTemp.manufacture" class="pin-box-toggle field is-grouped">
                      <b-checkbox v-model="licenseeConnectionTemp.manufacturePin">
                        Pin
                      </b-checkbox>
                      <span class="margin-x-2" />
                      <b-checkbox v-model="licenseeConnectionTemp.manufactureBox">
                        Box
                      </b-checkbox>
                    </div>
                  </div>
                </th>
                <th :class="{ inactive: !licenseeConnectionTemp.repair }">
                  <div class="field is-marginless has-text-centered">
                    <b-switch v-model="licenseeConnectionTemp.repair" size="is-small">
                      Repair
                    </b-switch>
                    <div v-if="licenseeConnectionTemp.repair" class="pin-box-toggle field is-grouped">
                      <b-checkbox v-model="licenseeConnectionTemp.repairPin">
                        Pin
                      </b-checkbox>
                      <span class="margin-x-2" />
                      <b-checkbox v-model="licenseeConnectionTemp.repairBox">
                        Box
                      </b-checkbox>
                    </div>
                  </div>
                </th>
                <th :class="{ inactive: !licenseeConnectionTemp.accessory }">
                  <div class="field is-marginless has-text-centered">
                    <b-switch v-model="licenseeConnectionTemp.accessory" size="is-small">
                      Accessory
                    </b-switch>
                    <div v-if="licenseeConnectionTemp.accessory" class="pin-box-toggle field is-grouped">
                      <b-checkbox v-model="licenseeConnectionTemp.accessoryPin">
                        Pin
                      </b-checkbox>
                      <span class="margin-x-2" />
                      <b-checkbox v-model="licenseeConnectionTemp.accessoryBox">
                        Box
                      </b-checkbox>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="mini_header">
                <td />
                <td>
                  <b-field grouped>
                    <b-field label="CRA">
                      <b-input />
                    </b-field>
                    <b-field label="Non">
                      <b-input />
                    </b-field>
                  </b-field>
                </td>
                <td>
                  <b-field grouped>
                    <b-field label="CRA">
                      <b-input />
                    </b-field>
                    <b-field label="Non">
                      <b-input />
                    </b-field>
                  </b-field>
                </td>
                <td>
                  <b-field grouped>
                    <b-field label="CRA">
                      <b-input />
                    </b-field>
                    <b-field label="Non">
                      <b-input />
                    </b-field>
                  </b-field>
                </td>
              </tr>
              <!-- <tr v-for="size in connection.sizes.filter(s => !s.deleted)" :key="size.id" :set="licenseeConnectionSize = licenseeConnectionTemp.sizes.find(s => s.connectionSizeId === size.id)"> -->
              <tr v-for="licenseeConnectionSize in licenseeConnectionTemp.sizes" :key="licenseeConnectionSize.connectionSizeId" :set="size = connection.sizes.find(s => s.id === licenseeConnectionSize.connectionSizeId)">
                <td>{{ licenseeConnectionSize.size }}"</td>
                <td :class="{ inactive: !licenseeConnectionTemp.manufacture }">
                  <b-field grouped>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceManCra"
                        :class="{ changed: licenseeConnectionSize.priceManCra }"
                        :placeholder="size.priceManCra"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.manufacture" />
                    </b-field>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceManNon"
                        :class="{ changed: !!licenseeConnectionSize.priceManNon }"
                        :placeholder="size.priceManNon"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.manufacture" />
                    </b-field>
                  </b-field>
                </td>
                <td :class="{ inactive: !licenseeConnectionTemp.repair }">
                  <b-field grouped>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceRepCra"
                        :class="{ changed: licenseeConnectionSize.priceRepCra }"
                        :placeholder="size.priceRepCra"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.repair" />
                    </b-field>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceRepNon"
                        :class="{ changed: !!licenseeConnectionSize.priceRepNon }"
                        :placeholder="size.priceRepNon"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.repair" />
                    </b-field>
                  </b-field>
                </td>
                <td :class="{ inactive: !licenseeConnectionTemp.accessory }">
                  <b-field grouped>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceAccCra"
                        :class="{ changed: licenseeConnectionSize.priceAccCra }"
                        :placeholder="size.priceAccCra"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.accessory" />
                    </b-field>
                    <b-field>
                      <b-input
                        v-model="licenseeConnectionSize.priceAccNon"
                        :class="{ changed: !!licenseeConnectionSize.priceAccNon }"
                        :placeholder="size.priceAccNon"
                        expanded
                        size="is-small"
                        :disabled="!licenseeConnectionTemp.accessory" />
                    </b-field>
                  </b-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <button class="button is-transparent has-text-grey" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-success" @click="save">
            Done
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  props: {
    licenseeId: {
      required: true,
      type: String
    },
    connection: {
      required: true,
      type: Object
    },
    licensee: {
      required: true,
      type: Object
    },
    licenseeConnection: {
      required: false,
      type: Object,
      default: null
    }
  },
  data () {
    return {
      licenseeConnectionTemp: {},
      loaded: false
    }
  },
  computed: {
    connectionSizesById () {
      const output = {}

      this.connection.sizes.forEach(size => {
        output[size.id] = size
      })

      return output
    }
  },
  async mounted () {
    this.licenseeConnectionTemp = this.licenseeConnection
    this.licenseeConnectionTemp.licenseeId = this.licenseeId
    this.licenseeConnectionTemp.connectionId = this.connection.id

    if (!this.licenseeConnectionTemp.manufacture) {
      this.licenseeConnectionTemp.manufacturePin = true
      this.licenseeConnectionTemp.manufactureBox = true
    }
    if (!this.licenseeConnectionTemp.repair) {
      this.licenseeConnectionTemp.repairPin = true
      this.licenseeConnectionTemp.repairBox = true
    }
    if (!this.licenseeConnectionTemp.accessory) {
      this.licenseeConnectionTemp.accessoryPin = true
      this.licenseeConnectionTemp.accessoryBox = true
    }
    await this.connection.sizes.filter(s => !s.deleted).forEach(async connectionSize => {
      var licenseeConnection = this.licenseeConnectionTemp.sizes.find(s => s.connectionSizeId === connectionSize.id && !s.deleted)
      if (!licenseeConnection) {
        this.licenseeConnectionTemp.sizes.push({
          id: 'new',
          licenseeId: this.licenseeId,
          connectionId: this.connection.id,
          connectionSizeId: connectionSize.id,
          size: connectionSize.size,
          priceManCra: null,
          priceManNon: null,
          priceRepCra: null,
          priceRepNon: null,
          priceAccCra: null,
          priceAccNon: null
        })
      } else {
        licenseeConnection.size = connectionSize.size
      }
    })
    // Sort ASC the array. Code from https://www.w3schools.com/jsref/jsref_sort.asp
    this.licenseeConnectionTemp.sizes.sort(
      function (a, b) {
        return a.size - b.size
      }
    )

    this.loaded = true
  },
  methods: {
    async save () {
      if (!this.licenseeConnectionTemp.manufacture) {
        this.licenseeConnectionTemp.manufacturePin = false
        this.licenseeConnectionTemp.manufactureBox = false
      } else {
        if ((!this.licenseeConnectionTemp.manufacturePin) && (!this.licenseeConnectionTemp.manufactureBox)) {
          this.licenseeConnectionTemp.manufacture = false
        }
      }
      if (!this.licenseeConnectionTemp.repair) {
        this.licenseeConnectionTemp.repairPin = false
        this.licenseeConnectionTemp.repairBox = false
      } else {
        if ((!this.licenseeConnectionTemp.repairPin) && (!this.licenseeConnectionTemp.repairBox)) {
          this.licenseeConnectionTemp.repair = false
        }
      }
      if (!this.licenseeConnectionTemp.accessory) {
        this.licenseeConnectionTemp.accessoryPin = false
        this.licenseeConnectionTemp.accessoryBox = false
      } else {
        if ((!this.licenseeConnectionTemp.accessoryPin) && (!this.licenseeConnectionTemp.accessoryBox)) {
          this.licenseeConnectionTemp.accessory = false
        }
      }

      if (this.licenseeId === 'new') {

      } else {
        // this.licenseeConnection = await AdminApi.updateLicenseeConnection(parseInt(this.licenseeId), this.licenseeConnectionTemp.id, this.licenseeConnection)
      }

      this.$emit('change', this.licenseeConnectionTemp)
      this.$parent.close()
    },
    close () {
      this.$emit('close', this.licenseeConnection)
      this.$parent.close()
    }
  }
}

</script>
