<template>
  <div v-if="loaded">
    <div v-if="licensee.suspended" class="columns is-gapless">
      <div class="column">
        <div class="padding-x-4 padding-x-5-tablet">
          <b-message type="is-danger" class="has-shadow-50">
            <div class="columns is-mobile is-vcentered">
              <div class="column is-narrow">
                <b-icon icon="ban" size="is-medium" />
              </div>
              <div class="column is-size-6">
                This licensee is suspended.
              </div>
              <div class="column is-narrow">
                <b-button class="is-primary" @click="unsuspendLicensee">
                  Unsuspend
                </b-button>
              </div>
            </div>
          </b-message>
          <hr>
        </div>
      </div>
    </div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="padding-x-4 padding-x-5-tablet">
          <div class="section-title">
            Licensee Info
          </div>
          <b-field class="required" label="Licensee Name" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
            <b-input v-model="licensee.name" v-validate="'required'" name="name" data-vv-as="name" />
          </b-field>
          <div class="columns">
            <div class="column">
              <b-field class="required" label="JFE Office" :type="{'is-danger': errors.has('officeId')}" :message="errors.first('officeId')">
                <b-select v-model="licensee.officeId" v-validate="'required'" expanded name="officeId" data-vv-as="JFE office">
                  <option v-for="office in offices" :key="office.id" :value="office.id">
                    {{ office.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field class="required" label="Code #" :type="{'is-danger': errors.has('code')}" :message="errors.first('code')">
                <b-input v-model="licensee.code" v-validate="'required'" name="code" data-vv-as="code" />
              </b-field>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column">
              <b-field class="required" label="Billing Cycle" :type="{'is-danger': errors.has('billingCycle')}" :message="errors.first('billingCycle')">
                <b-select v-model="licensee.billingCycle" v-validate="'required'" expanded name="billingCycle" data-vv-as="Billing Cycle">
                  <option>Monthly</option>
                  <option>Quarterly</option>
                  <option>Semiannually</option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Tax withholding"
                style="margin-bottom: 0.5em;" />
              <b-field>
                <b-input v-model="licensee.taxWithholdingPercent" type="number" min="0" max="100" step="0.001" expanded />
                <p class="control">
                  <span class="button is-static">%</span>
                </p>
              </b-field>
            </div>
          </div>
          <b-switch v-show="id != 'new'" v-model="licensee.canRequestSpecialRate" true-value="1" false-value="0" style="font-size: 14px">
            Can request special royalty rate
          </b-switch>
          <hr c>
          <div class="section-title is-marginless">
            Additional Notes
          </div>
          <p class="is-size-7">
            Admin-only details, not shown on app or website
          </p>
          <b-field class="margin-y-3">
            <b-input v-model="licensee.additionalNotes" type="textarea" rows="5" />
          </b-field>
        </div>
      </div>
      <div class="column">
        <div class="padding-xy-4 padding-y-0-tablet">
          <div class="section-title">
            Connections
          </div>
          <div class=" has-shadow-30">
            <b-table
              :data="connections"
              class="scrollable clickable-row no-header"
              :mobile-cards="false"
              :hoverable="true"
              @click="editLicenses">
              <b-table-column v-slot="props" label="Connection">
                <div class="has-text-centered" style="display: inline-flex; align-items: center;">
                  <img :src="props.row.images.find(i => i.name === 'Icon').screenshotUrl" style="width: 45px; margin-right: 7px;">
                  <div class="is-size-7 has-text-weight-bold">
                    {{ props.row.name }}
                  </div>
                </div>
              </b-table-column>
              <b-table-column v-slot="props" centered label="Permissions" field="jfelion_permissions" cell-class="permissions_column">
                <span class="perm" :class="{ active: permissionsByConnectionId[props.row.id].manufacture }">M</span>
                <span class="perm" :class="{ active: permissionsByConnectionId[props.row.id].repair }">R</span>
                <span class="perm" :class="{ active: permissionsByConnectionId[props.row.id].accessory }">A</span>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <hr class="margin-y-6">
    <div class="padding-x-4 padding-x-5-tablet">
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <div class="section-title is-marginless">
            Divisions
          </div>
        </div>
        <div class="column is-narrow">
          <b-button
            outlined
            size="is-small"
            type="is-primary"
            icon-right="plus-circle"
            @click="addDivision()">
            Add Division
          </b-button>
        </div>
      </div>
      <div class="">
        <div v-if="divisions.length === 0" class="no_divisions">
          <span>Add at least one division for this licensee.</span>
        </div>
        <b-collapse
          v-for="(division, index) of divisions"
          :key="division.id"
          class="card mb-2 panel"
          animation="slide"
          :open="divisionOpen == index"
          @open="divisionOpen = index">
          <template #trigger="props">
            <div
              class="card-header padding-xy-2"
              role="button">
              <p class="card-header-title is-size-5">
                {{ division.name }}
              </p>
              <a class="card-header-icon is-size-5">
                <b-icon v-if="division.billingDivision" icon="dollar-sign" class="has-text-success" size="is-small" />
                <b-icon v-if="props.open" key="open" icon="caret-down" />
                <b-icon v-else key="closed" icon="caret-left" />
              </a>
            </div>
          </template>
          <div class="card-content" style="flex: 1">
            <div class="columns">
              <div class="column">
                <b-field class="required" label="Division Name" :type="{'is-danger': errors.has('divisionName' + index)}" :message="errors.first('divisionName' + index)">
                  <b-input :ref="'divisionName' + index" v-model="division.name" v-validate="'required|validateDivisionName:division.name'" expanded autocomplete="off" :name="'divisionName' + index" data-vv-as="division name" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Licensee Number">
                  <b-input v-model="division.licenseeNumber" />
                </b-field>
              </div>
              <div class="column">
                <b-field class="field padding-y-1 is-marginless">
                  <b-checkbox :disabled="!!(division.billingDivision)" v-model="division.billingDivision" @input="chengeBillingDivision(division)">
                    Bill to this division
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <hr>
            <div class="columns">
              <div class="column">
                <div class="section-title">
                  Contact Info
                </div>
                <b-field label="Contact Name">
                  <b-input v-model="division.primaryContact" />
                </b-field>
                <b-field label="Email">
                  <b-input v-model="division.contactEmail" />
                </b-field>
                <div class="columns">
                  <div class="column">
                    <b-field label="Phone">
                      <b-input v-model="division.officePhone" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fax">
                      <b-input v-model="division.officeFax" />
                    </b-field>
                  </div>
                </div>
                <b-field label="Website">
                  <b-input v-model="division.website" />
                </b-field>
              </div>
              <div class="column">
                <div class="section-title">
                  Location
                </div>
                <b-field label="Region">
                  <b-select v-model="division.region" expanded>
                    <option value="null">
                      Choose...
                    </option>
                    <option value="Africa">
                      Africa
                    </option>
                    <option value="Asia">
                      Asia
                    </option>
                    <option value="Canada">
                      Canada
                    </option>
                    <option value="Central/South America">
                      Central/South America
                    </option>
                    <option value="China">
                      China
                    </option>
                    <option value="Europe">
                      Europe
                    </option>
                    <option value="Japan">
                      Japan
                    </option>
                    <option value="Mexico">
                      Mexico
                    </option>
                    <option value="Middle East">
                      Middle East
                    </option>
                    <option value="Pacific">
                      Pacific
                    </option>
                    <option value="Russia">
                      Russia
                    </option>
                    <option value="Southeast Asia">
                      Southeast Asia
                    </option>
                    <option value="USA">
                      USA
                    </option>
                  </b-select>
                </b-field>

                <b-field label="Address">
                  <b-input v-model="division.address" type="textarea" rows="3" />
                </b-field>
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field>
                      <p class="control">
                        <span class="button is-static">Lat</span>
                      </p>
                      <b-input v-model="division.latitude" expanded type="number" min="-90" max="90" step=".000000000000001" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <p class="control">
                        <span class="button is-static">Lon</span>
                      </p>
                      <b-input v-model="division.longitude" expanded type="number" min="-180" max="180" step=".000000000000001" />
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-switch v-model="division.showInDirectory" true-value="1" false-value="0" style="font-size: 14px; margin-top: 5px">
                      Show division in public directory
                    </b-switch>
                  </div>
                  <div class="column is-narrow">
                    <b-tooltip label="Set another division as bill location before delete." :active="!!(division.billingDivision)" multilined type="is-danger">
                      <b-button :disabled="!!(division.billingDivision)" icon-left="trash-alt" class="is-danger" @click="removeDivisionPrompt(division)" />
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="padding-y-8" />
    <manage-buttons :disabled-save="!canSave" @cancel="cancel" @save="save" />
  </div>
</template>

<script>

import AdminApi from '@/apis/AdminApi'
import LicenseEditor from '@/components/modals/LicenseEditor'
import ManageButtons from '@/components/navigation/ManageButtons'

export default {
  components: {
    ManageButtons
  },
  data () {
    return {
      licensee: {
        licenseeNumber: null,
        code: null,
        name: null,
        taxWithholdingPercent: 0.00,
        showInDirectory: true,
        billingCycle: null,
        region: null,
        address: null,
        latitude: null,
        longitude: null,
        officePhone: null,
        officeFax: null,
        website: null,
        additionalNotes: null
      },
      licenseeConnections: [],
      divisions: [],
      divisionsToDelete: [],
      divisionOpen: null,
      divisionInit: {
        licenseeId: null,
        name: null,
        licenseeNumber: null,
        region: null,
        address: null,
        latitude: null,
        longitude: null,
        primaryContact: null,
        contactEmail: null,
        officePhone: null,
        officeFax: null,
        website: null,
        showInDirectory: true
      },
      loaded: false
    }
  },
  computed: {
    id: {
      get () {
        return this.$route.params.id
      },
      set (val) {
        this.$route.params.id = val
      }
    },
    offices () {
      return this.$store.state.offices
    },
    connections () {
      return this.$store.state.connections.filter(c => c.royaltyAvailability)
    },
    permissionsByConnectionId () {
      const permissions = {}

      this.connections.forEach(connection => {
        const licenseeConnection = this.licenseeConnections.find(c => c.connectionId === connection.id)
        if (licenseeConnection) {
          permissions[connection.id] = licenseeConnection
        } else {
          permissions[connection.id] = {}
        }
      })

      return permissions
    },
    canSave () {
      return this.divisions.length > 0
    }
  },
  async mounted () {
    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshConnections')

    if (this.id === 'new') {
      await this.$store.commit('setTitle', 'New Licensee')
      this.addDivision()
    } else {
      this.licensee = await AdminApi.getLicenseeById(this.id)
      this.licenseeConnections = await AdminApi.getLicenseeConnectionsByLicenseeId({ id: this.id })
      this.divisions = await AdminApi.getLicenseeDivisions(this.id)

      if (this.divisions.length === 1) {
        this.divisions[0].billingDivision = true // Make sure at least one division is the billing division
      }
      for (let division of this.divisions) {
        division.billingDivision = !!(division.billingDivision)
      }

      await this.$store.commit('setTitle', 'Licensee: ' + this.licensee.name)
    }

    this.$validator.extend('validateDivisionName', {
      getMessage: field => 'Division name is already in use!',
      validate: async value => {
        try {
          const duplicatedDivisions = this.divisions.filter(d => d.name.toLowerCase() === value.toLowerCase())
          if (duplicatedDivisions.length > 1) {
            return false
          } else {
            return true
          }
        } catch (err) {
          return true
        }
      }
    })

    this.loaded = true
  },
  methods: {
    goToLicensee (licensee) {
      this.$router.push({
        name: 'manage_licensee',
        params: { id: licensee.id }
      })
    },
    chengeBillingDivision (division) {
      let indexDivision = String(this.divisions.indexOf(division))

      if (this.divisions.length > 1) {
        for (const index in this.divisions) {
          this.divisions[index].billingDivision = !!(index === indexDivision)
        }
      } else {
        if (this.divisions.length === 1) {
          this.divisions[indexDivision].billingDivision = true
        }
      }
    },
    unsuspendLicensee (licensee) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to unsuspend this licensee?',
        confirmText: 'Unsuspend Licensee',
        type: 'is-success',
        onConfirm: async () => {
          var licensee = await AdminApi.getLicenseeById(this.$route.params.id)
          if (licensee) {
            licensee.suspended = false
            await AdminApi.updateLicensee(this.$route.params.id, licensee)

            this.$router.push({ name: 'manage_licensees' })

            this.$buefy.toast.open('Licensee unsuspended')
          }
        }
      })
    },

    async save () {
      if (await this.$validator.validateAll()) {
        if (this.id === 'new') {
          this.licensee = await AdminApi.createLicensee(this.licensee)
        } else {
          this.licensee = await AdminApi.updateLicensee(this.id, this.licensee)
        }
        this.divisionsToDelete.map(async division => {
          if (division.id) {
            await AdminApi.deleteLicenseeDivision(this.licensee.id, division.id)
          }
        })
        this.divisions.map(async division => {
          division.licenseeId = this.licensee.id
          if (!division.id) {
            division = await AdminApi.createLicenseeDivision(division)
          } else {
            division = await AdminApi.updateLicenseeDivisions(division)
          }
        })

        // Add the Virtual Array licenseeConnections
        for (const licenseeConnection of this.licenseeConnections) {
          if ((licenseeConnection.id == null) || (licenseeConnection.id === 'new')) {
            licenseeConnection.licenseeId = this.licensee.id
            await AdminApi.createLicenseeConnection(this.licensee.id, licenseeConnection)
          } else {
            await AdminApi.updateLicenseeConnection(this.licensee.id, licenseeConnection.id, licenseeConnection)
          }
        }
        this.$router.push({ name: 'manage_licensees' })
        this.$buefy.toast.open('Licensee saved')
      }
    },
    async cancel () {
      this.$router.push({ name: 'manage_licensees' })
    },
    async addDivision () {
      this.divisions.unshift({
        id: null,
        licenseeId: this.licensee.id,
        name: this.divisions.length > 0 ? 'Division ' + parseInt(this.divisions.length + 1) : 'Headquarters',
        licenseeNumber: this.divisionInit.licenseeNumber,
        region: this.divisionInit.region,
        address: this.divisionInit.address,
        latitude: this.divisionInit.latitude,
        longitude: this.divisionInit.longitude,
        primaryContact: this.divisionInit.primaryContact,
        contactEmail: this.divisionInit.contactEmail,
        officePhone: this.divisionInit.officePhone,
        officeFax: this.divisionInit.officeFax,
        website: this.divisionInit.website,
        billingDivision: this.divisions.length === 0,
        showInDirectory: '1'
      })
      this.divisionOpen = 0
    },
    async removeDivisionPrompt (division) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this division?',
        confirmText: 'Delete Division',
        type: 'is-danger',
        onConfirm: async () => {
          this.removeDivision(division)
        }
      })
    },
    async removeDivision (division) {
      this.divisionsToDelete.push(division)
      this.divisions.splice(this.divisions.indexOf(division), 1)
    },
    async editLicenses (connection) {
      var licenseeConnectionDefault = {
        id: null,
        licenseeId: null,
        connectionId: null,
        size: null,
        manufacture: false,
        manufacturePin: true,
        manufactureBox: true,
        repair: false,
        repairPin: true,
        repairBox: true,
        accessory: false,
        accessoryPin: true,
        accessoryBox: true,
        techDocsPin: false,
        techDocsBox: false,
        inspSheetsPin: false,
        inspSheetsBox: false,
        sizes: []
      }
      this.$buefy.modal.open({
        component: LicenseEditor,
        props: {
          licenseeId: String(this.id),
          connection: connection,
          licensee: this.licensee,
          licenseeConnection: this.licenseeConnections.find(c => c.connectionId === connection.id) || licenseeConnectionDefault
        },
        events: {
          change: async (licenseeConnection) => {
            // if(this.id === 'new'){
            var licenseeConnectionIndex = this.licenseeConnections.indexOf(licenseeConnection)

            if (licenseeConnectionIndex === -1) {
              this.licenseeConnections.push(licenseeConnection)
            }
          },
          close: async (licenseeConnection) => {
            console.log('just cancelled')
          }
        }
      })
    }
  }
}

</script>

<style lang="css" scoped>
.no_divisions {
  color: red;
}
</style>
